/* RIBBON */
.corner-ribbon{
  width: 200px;
  background: #e43;
  position: absolute;
  top: 25px;
  left: -50px;
  text-align: center;
  line-height: 40px;
  letter-spacing: 1px;
  color: #f0f0f0;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Custom styles */

.corner-ribbon.sticky{
  position: fixed;
}

.corner-ribbon.shadow{
  box-shadow: 0 0 3px rgba(0,0,0,.3);
}

/* Different positions */

.corner-ribbon.top-left{
  top: 25px;
  left: -50px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.corner-ribbon.top-right{
  top: 25px;
  right: -50px;
  left: auto;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-left{
  top: auto;
  bottom: 25px;
  left: -50px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.corner-ribbon.bottom-right{
  top: auto;
  right: -50px;
  bottom: 25px;
  left: auto;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

/* Colors */

.corner-ribbon.lobra{background: #ea515b}



/* FINE RIBBON */



#login_riquadro{
  width: 350px;
  height: 450px;
  margin:auto;
  margin-top: 2%;
  border-color: black;
  border-style: solid;
  border-width: 1px;
}

#sceltaprogetto_riquadro{
  margin-left: 45%;
  width: 350px;
  height: 450px;
  margin:auto;
  margin-top: 2%;
}
 
#Logo{
  margin-top: 10%;
  width: 70%;
}
.Spazio{
  height: 10px;
}
 
#email{
  margin-top:30%;
   width: 1200px;
  margin-bottom: 10%;
  border-color: #ea515b;
}
 
#password{
  border-color: #ea515b;
}
 
#bottone_login{
  margin-top: 9%;
  width: 100px;
  color: white;
  background-color: #ea515b;
  margin-bottom: 4%;
}

#link_route{
  color: #ea515b;
  margin: auto;
}

#bottone_login_signup{
  margin-top: 0%;
  width: 100px;
  color: white;
  background-color: #ea515b;
}

#card_errors_signup{
  border: 1px solid #ea515b;
  width: 90%;
  border-radius: 6px;
  margin: auto;
  margin-bottom: 14px;
}
 
.login_class{
  width: 240%;
  margin-left: 13%
}
 
#Logo_2{
  width: 50%; 
 margin:auto;
 margin-top: 5%
}
 
#Titolo{
  margin-top: 2%;
}
 
#menu{
  margin-left: 35px;
  margin-top: 30%;
}
 
#tendina{
  margin-top:10%;
  margin-left: 20%;
  width: 200px;
}
 
#icona{
  color:#ea515b;
  margin-left: 15%;
}
 
.prog_attivi{
  margin-top: 1%;
}
#radio{
  margin-left: 10%;
}
 
#lista_prog{
  border-color: #ea515b;
}
 
#mese{
  border-color: #ea515b;
  width: 50%;
  display: inline-block;
}
 
#anno{
  width:50%;
  border-color: #ea515b;
  display: inline-block;
}
 
#ok{
  background-color: #ea515b;
  color: white;
}
#tabella{
  width: 90%;
  margin-top:5%;
}
 
.input{
  width: 30%;
}
 
.nome_progetto{
  width: 16.1%;
  
}
 
.costi{
  width: 17%;
}
.trasferta{
  width:10%;
}
.giorni{
  width:54%;
}
 
#piano_contabile{
  width: 120%;
  margin-left: 4%;
}
#piano_economico{
  width: 70%;
  margin-left: 25%;
}
 
.fine{
  background-color: blueviolet;
}

#link_menu{
  font-size: 18px;
  margin-bottom: 5px;
}

#error_msg_login {
  margin-top: 7%;
  width: 80%;
  height: 40%
}

#sottomenu{
  margin-left: 5px;
  font-size: 23px;
  color: #001722;
}

#dropdown_emp{
  margin-left: 60px;
  margin-top: 35px;
  font-size: 21px;
  color: #f65058;
}



/* inizio help desk */

#titolo_change_pw{
margin-top: 10%;
margin-bottom: 10%;
text-align: center;
}
 
#input_registrazione{
width: 1200px;
margin-bottom: 10%;
border-color: #ea515b;
}
 
#magine_password_registrazione{
width: 1200px;
margin-bottom: 2%;
border-color: #ea515b;
}
 
#cambio_pw_riquadro{
width: 350px;
height: 650px;
margin:auto;
margin-top: 2%;
border-color: black;
border-style: solid;
border-width: 1px;
}
 
#registrazione_riquadro{
width: 350px;
height: 100%;
margin:auto;
margin-top: 2%;
border-color: black;
border-style: solid;
border-width: 1px;
}

#registrazione_riquadro_errore{
width: 350px;
height: 250px;
margin:auto;
margin-top: 2%;
border-color: black;
border-style: solid;
border-width: 1px;
}

#bottone_nuovo_ticket{
  width: 170px;
  color: white;
  background-color: #ea515b;
}

#tab_ticket{
  width: 90%;
}

#dropdown, #descrizione{
  border-color: #ea515b;
}

#eseguito_da{
  margin-top: 10px;
}

/* #label{
  margin-top: 5px;
} */

#giustificazione_destra{
  text-align: right;
  font-weight: bold;
  margin-right: 20px;
}

#grandezza_fissa_tag{
  width: 100%;
/*   min-height: 21px; */
min-height: 26px;
}
#grandezza_fissa_descrizione{
  min-height: 80px;
  width: 100%;
}


#input_rossi_100p{
  border-color: #ea515b;
  width:100%;
}

#input_rossi_oggetto{
  border-color: #ea515b;
  width:100%;
}

#input_rossi_helpdteam{
  border-color: #ea515b;
  width:100%;
}

#input_rossi_90p{
  border-color: #ea515b;
  width:70%;
  max-width: 70%;
}

.categoria{
  margin-right: 5px;
}

#tabella_lista_ticket{
  width: 90%;
}

#priorita, #categoria, #tipologia, #helpdeskteam_label{
  float: left;
  margin-right: 0px;
}

#caricamento_icona{
  position: absolute;
  top: 50%;
  left: 50%;
}
 
#caricamento_scritta{
  position: absolute;
  top: 60%;
  left: 46%;
}

#santa_pazienza_errore{
  text-align: center;
  margin-left: 15px;
  margin-right: 15px;
}

#textarea_no_border{
  border: 1px solid rgba(34, 36, 38, 0);
}

/* tile uploaded pictures */
.upload-list-inline .ant-upload-list-item {
  float: left;
  width: 200px;
  margin-right: 8px;
}
.upload-list-inline [class*='-upload-list-rtl'] .ant-upload-list-item {
  float: right;
}
.upload-list-inline .ant-upload-animate-enter {
  animation-name: uploadAnimateInlineIn;
}
.upload-list-inline .ant-upload-animate-leave {
  animation-name: uploadAnimateInlineOut;
}

.ant-switch-checked {
  background-color: #ea515b
}

.ant-table .ant-table-title {
  border: 1px solid #e2e2e2;
  border-bottom: 0;
}
.ant-table thead > tr > th,
.ant-table tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  border-right: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
}
.ant-table .ant-table-cell-fix-right-first::after {
  border-right: 1px solid #e2e2e2;
}
.ant-table table thead > tr:not(:last-child) > th {
  border-bottom: 1px solid #e2e2e2;
}
.ant-table .ant-table-container {
  border: 1px solid #e2e2e2;
  border-right: 0;
  border-bottom: 0;
}
.ant-table .ant-table-expanded-row-fixed {
  margin: -16px -17px;
}
.ant-table .ant-table-expanded-row-fixed::after {
  position: absolute;
  top: 0;
  right: 1px;
  bottom: 0;
  border-right: 1px solid #e2e2e2;
  content: '';
}
.ant-table.ant-table-scroll-horizontal tr.ant-table-expanded-row > td,
.ant-table.ant-table-scroll-horizontal tr.ant-table-placeholder > td {
  border-right: 0;
}
.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
  margin: -12px -9px;
}
.ant-table.ant-table-small .ant-table-expanded-row-fixed {
  margin: -8px -9px;
}
.ant-table .ant-table-footer {
  border: 1px solid #e2e2e2;
  border-top: 0;
}

.ant-table-tbody > tr > td{
  border-bottom: 1px solid #e2e2e2;

}

.ant-table-thead > tr > th{
  border-bottom: 1px solid #e2e2e2;
}

